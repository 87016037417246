<template>
    <v-img
    :src="imageSource"
    :lazy-src="imageSource"
    aspect-ratio="1"
    class="grey darken-4"
    :class="templateClass">

        <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center">

                <v-progress-circular
                    indeterminate
                    color="grey lighten-5">
                </v-progress-circular>

            </v-row>
        </template>
    </v-img>
</template>

<script>
export default {
    name: 'ImageDiv',
    props: {
        imageSource: String,
        templateClass: String
    },
}
</script>

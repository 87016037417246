<template>
     <v-fade-transition>
        <v-overlay
            absolute
            color="#181717" v-if="isLoading">
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
        </v-overlay>
    </v-fade-transition>
</template>
<script>
export default {
    name: 'LoadingIcon',
    props: {
        isLoading: Boolean,
        imgClass: String
    },
}
</script>
<style>
.hoverable-img 
{
    border-radius: 0px !important;
    cursor: pointer;
}
</style>

<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <v-container v-if="!isLoading" class="px-5">
            <v-row no-gutters>
                <v-col>
                    <p class="display-1 mb-2">Afgelopen roadtrips</p>
                    <div>{{intro}}</div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="px-5">
            <template v-for="(yearEvent, index) in roadtripsPerYear" >
                <div :key="index">
                    <v-container>
                        <v-row>
                            <v-col class="col-12 display-1 pa-0">{{yearEvent.year}}</v-col>
                        </v-row>
                    </v-container>

                    <template v-for="(event, event_index) in yearEvent.events" >
                        <div class="" :key="event_index">
                            <v-container class="pa-0" >
                                <v-row no-gutters >
                                    <v-col class="col-12 detail-header py-0">{{event.name}} ({{formatDayMonth(getDateOnly(event.date))}})</v-col>
                                    <v-col class="col-12 rating px-0 pt-0 mt-n1 mb-1 d-flex">
                                        <v-rating
                                            v-model="event.averageRating"
                                            background-color="#b5b5b5"
                                            color="yellow accent-4"
                                            half-increments
                                            dense
                                            readonly
                                            size="18"></v-rating>
                                        <div class="comments">{{event.averageRating}} ({{event.numberOfRatings}})</div>
                                    </v-col>
                                </v-row>
                                <ImageRedirect @imgClick="goToPastTrip(event.name, formatDate(getDateOnly(event.date)), event.roadtripId)" :imageSource="event.imageURL" :imgClass="'galleryImg'" />
                            </v-container>
                        </div>
                    </template>
                </div>
            </template>
        </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import ImageRedirect from '@/components/common/ImageRedirect'
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'AfgelopenRoadtrips',
    components: 
    {
        LoadingIcon,
        ImageRedirect,
    },
    mixins: [FormatMixin, RouteMixin, MetaTagsMixin],
    computed: {
        ...mapState('authorizedModule', ['userId']),
    },
    data: () => ({
        intro: "Hieronder kunt u zien aan welke roadtrips u heeft meegedaan. Alle trips zijn per jaar gesorteerd, zodat u ze gemakkelijk kunt terugvinden. Klik op de roadtrip die u wilt bekijken. Hier vindt u meer informatie, denk bijvoorbeeld aan de factuur en een linkje naar de galerij.",
        roadtripsPerYear: [],
        isLoading: false,
    }),
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        this.getPastRoadtrips();
    },
    metaInfo() {
        return {
        title: `| Afgelopen Roadtrips`,
        meta: [
            this.getKeywords(`Afgelopen Roadtrips`),
            this.getMetaDescription(`Afgelopen Roadtrips`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Afgelopen Roadtrips`, `Afgelopen Roadtrips`, '', ''),
        ]
        };
    },
    methods: {
        getPastRoadtrips()
        {
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/getPassedRoadtrips', this.userId)
                .then(data => {

                let self = this;
                let roadtrips = [];
                data.forEach(trip => {
                    let roadtrip = {...trip};
                    roadtrip.year = this.getYearOnly(trip.date);
                    roadtrips.push(roadtrip);
                });

                const groupedByYear = [...new Set(roadtrips.map(x => x.year))]
                groupedByYear.forEach(year => {
                    let groupedRoadtripsByYear = roadtrips.filter(x => x.year == year);
                    self.roadtripsPerYear.push({
                        year: year,
                        events: groupedRoadtripsByYear
                    })
                });

                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        }
    }
  }
</script>

<style>
.galleryImg
{
    height: 280px;
    margin-top: -10px;
    margin-bottom: 15px;
    cursor: pointer;
}

</style>

export default {
    data: function() {
        return {
        }
    },
    methods: 
    {
        getKeywords(content)
        {  
            return {
                vmid: "keywords",
                name: "keywords",
                content: `Roadpursuit, roadtrip, roadtrips, rijden, toeren, toerrit, rit, driving, passie, cars, auto's, liefhebbers, auto community, ${content}`
            }
        },
        getMetaDescription(content)
        {  
            return {
                vmid: "description",
                name: "description",
                content: content
            }
        },
        getAuthor()
        {
            return {
                vmid: "author",
                name: "author",
                content: `Roadpursuit`
            }
        },

        getSocialMediaMetas(title, description, imageUrl, imageAlt)
        {
            return [
                ...this.getMetaOGs(title, description, imageUrl, imageAlt),
                ...this.getMetaTwitters(title, description, imageUrl, imageAlt)
            ];

        },
        
        // OG -> Open Graph / Facebook tags
        // have a very nice preview when sharing your website on facebook.
        getMetaOGs(title, description, imageUrl, imageAlt)
        {
            let metas = [];
            metas.push(this.getMetaOGTitle(title));
            metas.push(this.getMetaOGDescription(description));

            if (imageUrl != "")
            {
                metas.push(this.getMetaOGImageSource(imageUrl));
                metas.push(this.getMetaOGImageAlt(imageAlt));
            }
           
            metas.push(this.getMetaPageUrl());
            metas.push(this.getMetaOGType());
            return metas;
        },
        getMetaOGType()
        {  
            return {
                vmid: "og:type",
                property: "og:type",
                content: "website"
            }
        },
        getMetaOGTitle(content)
        {  
            return {
                vmid: "og:title",
                property: "og:title",
                content: content
            }
        },
        getMetaOGDescription(content)
        {  
            return {
                vmid: "og:description",
                property: "og:description",
                content: content
            }
        },
        getMetaOGImageSource(content)
        {  
            return {
                vmid: "og:image",
                property: "og:image",
                content: content // image url
            }
        },
        getMetaOGImageAlt(content)
        {  
            return {
                vmid: "og:image:alt",
                property: "og:image:alt",
                content: content
            }
        },
        getMetaPageUrl()
        {  
            return {
                vmid: "og:url",
                property: "og:url",
                content: `${window.location.origin}${this.$router.currentRoute.fullPath}`
            }
        },

        // Twitter tags
        // have a nice twitter preview when sharing your website on twitter
        getMetaTwitters(title, description, imageUrl, imageAlt)
        {
            let metas = [];
            metas.push(this.getMetaTwitterCard());
            metas.push(this.getMetaTwitterTitle(title));
            metas.push(this.getMetaTwitterDescription(description));

            if (imageUrl != "")
            {
                metas.push(this.getMetaTwitterImage(imageUrl));
                metas.push(this.getMetaTwitterImageAlt(imageAlt));
            }
           
            metas.push(this.getMetaTwitterSite());
            metas.push(this.getMetaTwitterCreator());
            return metas;
        },
        getMetaTwitterCard()
        {
            return {
                vmid: "twitter:card",
                name:"twitter:card",
                content: "summary" // must be set to a value of “summary”
            }
        },
        getMetaTwitterTitle(content)
        {
            return {
                vmid: "twitter:title",
                name:"twitter:title", 
                content: content
            }
        },
        getMetaTwitterDescription(content)
        {
            return {
                vmid: "twitter:description",
                name:"twitter:description",
                content: content
            }
        },
        getMetaTwitterImage(content)
        {
            return {
                vmid: "twitter:image",
                name:"twitter:image",
                content: content
            }
        },
        getMetaTwitterImageAlt(content)
        {
            return {
                vmid: "twitter:image:alt",
                name:"twitter:image:alt", 
                content: content // maximum 420 characters.
            }
        },
        getMetaTwitterSite()
        {
            return {
                vmid: "twitter:site",
                name:"twitter:site",
                content: "@roadpursuit" // the Twitter @username the card should be attributed to.
            }
        },
        getMetaTwitterCreator()
        {
            return {
                vmid: "twitter:creator",
                name:"twitter:creator",
                content: "Roadpursuit"
            }
        }
    }
}
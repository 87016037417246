<template>
    <v-hover v-slot="{ hover }" class="hoverable-img">
        <v-card
        @click="imgClick()"
        :elevation="hover ? 12 : 2"
        :class="{ 'on-hover': hover }">

            <ImageDiv :imageSource="imageSource" :templateClass="imgClass" />

            <v-fade-transition>
                <v-overlay
                    class="hover-image-overlay"
                    v-if="hover"
                    absolute
                    color="#000">
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-hover>
</template>

<script>
import ImageDiv from '@/components/common/ImageDiv'

export default {
    name: 'ImageRedirect',
    props: {
        imageSource: String,
        imgClass: String
    },
    components: {
        ImageDiv
    },
    data: () => 
    ({
    }),
    methods: {
        imgClick() {
            this.$emit('imgClick');
        }
    }
}
</script>
<style>
.hoverable-img 
{
    border-radius: 0px !important;
    cursor: pointer;
}
</style>
